import React, { useState, useEffect } from 'react';
import { ChannelScheduleCard } from '../ChannelScheduleCard';
import { dateService } from '../../domain/services/dateService';
import { conf } from '../../domain/services/configService';
import { apiClientService } from '../../domain/services/apiClientService';

const fetchFirstShowNowOnTV = async () => {
  if (!conf.tvSettings.natgeo_tv.channel_map) {
    return null;
  }
  const now = new Date().getTime();
  const url = `/ajax/tv_schedule_by_date/${
    conf.tvSettings.natgeo_tv.channel_map[0].machine_name
  }/schedule_${dateService.format(now, 'Y-m-d')}.json`;
  const { response: shows } = await apiClientService.getJson(url);

  const firstShow = Array.isArray(shows)
    ? shows.find(
        value =>
          Number(value.timestamp) <= Number(now / 1000) &&
          Number(value.end_timestamp) >= Number(now / 1000)
      )
    : null;

  return firstShow;
};

export const NowOnTv = () => {
  const [show, setShow] = useState(null);

  useEffect(() => {
    fetchFirstShowNowOnTV().then(firstShow => setShow(firstShow));
  }, []);

  return show ? (
    <ChannelScheduleCard
      url={show.link}
      pageStatus={show.status}
      header={show.title}
      time={show.timestamp * 1000}
      size="medium"
      aspect="16x9"
    />
  ) : (
    <div />
  );
};
